import { gql, useMutation } from '@apollo/client';
import { cartLinesFragment } from '../fragments/cartLines';


// Utils
import { useGlobalContext } from 'utils/globalContext';
import { sendGoogleAnalyticsEvent } from 'utils/GoogleAnalytics';
import { SiteContext } from 'utils/SiteProvider';
import { useContext } from 'react';


// TODO: Convert selections to qgl fragment as we are using it also in queries/getCart
export const DELETE_CART_LINE = gql`
	mutation deleteCartLine($input: DeleteCartLineInput!) {
		deleteCartLine(input: $input) {
			id
			itemCount
			lines {
				...CartLinesFragment
			}
			subtotal
			discountTotal
			freightTotal
			taxTotal
			total
		}
	}
	${cartLinesFragment}
`;

export const useDeleteCartLine = () => {
	const { dispatch } = useGlobalContext();
	const site:any = useContext(SiteContext);

	const [mutate, { data, loading, error }] = useMutation(DELETE_CART_LINE, {
		update(cache, { data }) {
		},
	});

	const customMutate = (line:any) => {
		return new Promise((resolve, reject) => {
			const variables = {
				input: {
					productId: line?.productId,
					unitOfMeasureId: line?.price?.unitOfMeasure?.id
				}
			};
			const price = parseFloat(line?.price?.price);
			const quantity = line?.price?.quantity;
			mutate({ variables }).then((response:any) => {
				sendGoogleAnalyticsEvent('remove_from_cart', { 
					currency: site?.settings?.currency,
					value: price * quantity,
					items: [{
						'item_id': line?.productId,
						price,
						quantity
					}]
				});
				dispatch({
					type: 'toast',
					payload: { 
						open: true,
						severity: 'success',
						message: 'Item removed from order successfully',
					},
				});
			}).catch(reject);
		});
	};
	return { mutate: customMutate, data, loading, error };
};
