import React, { useState, useEffect, ChangeEvent } from 'react';

// Operations
import { useAddToCart } from 'operations/mutations/addToCart';
import { useUpdateCartLine } from 'operations/mutations/updateCartLine';
import { useDeleteCartLine } from 'operations/mutations/deleteCartLine';

// Components
import AddToMyList from 'components/MyList/addToMyList';

// Helpers
import { formatCurrency } from 'helpers/currency';

//Ability
import Ability from 'components/Ability';

// Icons
import InStockIcon from '@material-ui/icons/CheckCircleOutlined';
import NoStockIcon from '@material-ui/icons/ErrorOutlineOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import AddToCartIcon from '@material-ui/icons/AddShoppingCart';

// Colors
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/OutlinedInput';


interface Props {
	productId: string;
	price: any;
	quantity?: number;
	available: number;
	allocated: number;
	bottlesPerCase: number;
	variant: 'add' | 'addBig' | 'edit' | 'readonly';
	canAddToCart: boolean;
	unitOfMeasureId: number;
  expired?: boolean | undefined;
};

const UnitOfMeasureItem: React.FC<Props> = (props) => {
	const classes = useStyles();
  const uomName = props.price?.unitOfMeasure?.name || props.price?.unitOfMeasure?.displayName;
	const unitAvailable = (uomName?.toLowerCase() === 'case') ? Math.floor((props.available / props.bottlesPerCase) - props.allocated) : props.available - props.allocated;
	// State
	const [quantity, setQuantity] = useState(0);
	const [cartProgress, setCartProgress] = useState('');
	const [updateProgress, setUpdateProgress] = useState('');

	// Operations
	const { mutate: addToCart } = useAddToCart();
	const { mutate: updateCartLine } = useUpdateCartLine();
	const { mutate: deleteCartLine } = useDeleteCartLine();

	useEffect(() => {
		setQuantity(props.quantity || 0);
	}, [props.quantity]);


	const handleSubmit = (event:any) => {
		event.preventDefault();
		handleUpdate();
	};


	const handleAdd = async () => {
		setCartProgress('loading');
		try {
			await addToCart(props.productId, props.price?.unitOfMeasure.id, (quantity > 0) ? quantity : 1);
			setTimeout(() => {
				setCartProgress('success');
				setTimeout(() => {
					setCartProgress('');
				}, 2000);
			}, 500);
		}
		catch(error) {
			console.error('Unable to add to cart', error);
			setCartProgress('error');
			setTimeout(() => {
				setCartProgress('');
			}, 2000);
		}
	};

	const handleUpdate = async () => {
		if(quantity === 0)
			{handleDelete();}
		else {
			try {
				setUpdateProgress('loading');
				await updateCartLine(props.productId, props.price?.unitOfMeasure?.id, quantity);
				setTimeout(() => {
					setUpdateProgress('success');
					setTimeout(() => {
						setUpdateProgress('');
					}, 2000);
				}, 500);
			}
			catch {
				setQuantity(props.quantity || 1);
				setTimeout(() => {
					setUpdateProgress('error');
					setTimeout(() => {
						setUpdateProgress('');
					}, 2000);
				}, 500);
			}
		}
	};

	const handleDelete = async () => {
		try {
			await deleteCartLine(props);
		}
		catch {}
	};

	let addToCartTooltip = '';
	let disableAddToCart = false;

	if(!props.canAddToCart) {
		addToCartTooltip = 'Oops, you do not have permission to perform this action. Please contact your Account Owner or Super User.';
		disableAddToCart = true;
	}
	else if(unitAvailable === 0 && props.allocated === 0) {
		addToCartTooltip = 'Out of Stock items cannot be ordered, please contact your Sales Rep.';
		disableAddToCart = true;
	}
	else if((quantity === 0 ?1: quantity) > unitAvailable) {
		addToCartTooltip = 'Limited quantities, please contact your Sales Rep.';
		disableAddToCart = true;
	}
	else {
		addToCartTooltip = 'Add to Order';
	}

  const updateQuantity = (quantity: number) => {
    if (quantity > unitAvailable) {
      setQuantity(unitAvailable);
    } else if ( quantity < 0) {
      setQuantity(0);
    } else {
      setQuantity(quantity);
    }
  }

	if(props.variant === 'readonly')
		{return (
			<div className={classes.root}>
				{unitAvailable < 1 ? (
					<Tooltip arrow title="Out of Stock" enterTouchDelay={10} leaveTouchDelay={3000}>
						<NoStockIcon className={classes.noStock}/>
					</Tooltip>
				):(
					<Tooltip arrow title="Available" enterTouchDelay={10} leaveTouchDelay={3000}>
						<InStockIcon className={classes.inStock}/>
					</Tooltip>
				)} 
				<b>{ props.price?.unitOfMeasure?.displayName }:</b>
				<Box flexGrow={1} marginRight={1}></Box>
				{formatCurrency(props.price?.price || props.price?.value)}
				<span>&nbsp;x&nbsp;{quantity}&nbsp;&nbsp;</span>
			</div>
		);}

	return (
    <div className={classes.root}>
      {props.variant === 'edit' && (
        <IconButton aria-label="delete cart item" onClick={handleDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
      {unitAvailable < 1 ? (
        <Tooltip
          arrow
          title="Out of Stock"
          enterTouchDelay={10}
          leaveTouchDelay={3000}
        >
          <NoStockIcon className={classes.noStock} />
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          title="Available"
          enterTouchDelay={10}
          leaveTouchDelay={3000}
        >
          <InStockIcon className={classes.inStock} />
        </Tooltip>
      )}
      <b>{props.price?.unitOfMeasure?.displayName}:</b>
      <Box flexGrow={1} marginRight={1}></Box>
      {formatCurrency(props.price?.price || props.price?.value)}
      <span>&nbsp;x&nbsp;</span>
      <form onSubmit={handleSubmit}>
        <label>
          <Input
            type="number"
            name="quantity"
            aria-label="quantity"
            aria-placeholder="quantity"
            inputProps={{ min: 0, max: unitAvailable }}
            className={disableAddToCart ? classes.input + ' ' +classes.inputDisabled : classes.input}
            value={quantity}
            disabled={disableAddToCart || props.expired}
            onChange={(
              e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => updateQuantity(parseInt(e.target.value, 10))}
          />
        </label>
      </form>
      {props.variant === 'edit' && (
        <Box width={40} marginRight={0.5}>
          {updateProgress === 'loading' && (
            <Button
              variant="contained"
              color="primary"
              className={classes.addButton}
              fullWidth
              size="small"
              disableRipple
              disableElevation
              aria-label="loading"
            >
              <CircularProgress color="inherit" size={20} />
            </Button>
          )}
          {updateProgress === 'success' && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              fullWidth
              size="small"
              disableRipple
              disableElevation
              onClick={() => setUpdateProgress('')}
              aria-label="success"
            >
              <CheckIcon />
            </Button>
          )}
          {updateProgress === 'error' && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              fullWidth
              size="small"
              disableRipple
              disableElevation
              onClick={() => setUpdateProgress('')}
              aria-label="error"
            >
              <ErrorIcon />
            </Button>
          )}
          {updateProgress === '' && (
            <Button
              color="primary"
              variant="outlined"
              className={classes.addButton}
              onClick={handleUpdate}
              aria-label="save"
            >
              <SaveIcon />
            </Button>
          )}
        </Box>
      )}
      {props.variant === 'addBig' && (
        <>
          <Ability subject="PlanningSheets" action="read">
            <Box marginRight={0.5}>
              <Tooltip title="Add to Planning Sheet" enterTouchDelay={500} leaveTouchDelay={1000}>
                <div>
                  <AddToMyList
                    productId={parseInt(props.productId)}
                    quantity={quantity}
                    unitOfMeasureId={props.unitOfMeasureId}
                    addBig
                    disabled={props.expired}
                  />
                </div>
              </Tooltip>
            </Box>
          </Ability>

          <Box width={91} marginRight={0.5}>
            {cartProgress === 'loading' && (
              <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                fullWidth
                size="small"
                disableRipple
                disableElevation
                aria-label="loading"
              >
                <CircularProgress color="inherit" size={20} />
              </Button>
            )}
            {cartProgress === 'success' && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                fullWidth
                size="small"
                disableRipple
                disableElevation
                startIcon={<CheckIcon />}
                onClick={() => setCartProgress('')}
                aria-label="success"
              >
                Added
              </Button>
            )}
            {cartProgress === 'error' && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                fullWidth
                size="small"
                disableRipple
                disableElevation
                startIcon={<ErrorIcon />}
                onClick={() => setCartProgress('')}
                aria-label="error"
              >
                Failed
              </Button>
            )}
            {cartProgress === '' && (
              <Tooltip title={addToCartTooltip} enterTouchDelay={500} leaveTouchDelay={1000}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.addButton}
                    fullWidth
                    disableElevation
                    disabled={disableAddToCart || props.expired}
                    startIcon={<AddToCartIcon />}
                    onClick={handleAdd}
                    aria-label="add to order"
                  >
                    Order
                  </Button>
                </div>
              </Tooltip>
            )}
          </Box>
        </>
      )}
      {props.variant === 'add' && (
        <>
          <Ability subject="PlanningSheets" action="read">
            <Box width={40} marginRight={0.5}>
              <Tooltip title="Add to Planning Sheet" enterTouchDelay={500} leaveTouchDelay={1000}>
                <div>
                  <AddToMyList
                    productId={parseInt(props.productId)}
                    quantity={quantity}
                    unitOfMeasureId={props.unitOfMeasureId}
                    disabled={props.expired}
                  />
                </div>
              </Tooltip>
            </Box>
          </Ability>

          <Box width={40} marginRight={0.5}>
            {cartProgress === 'loading' && (
              <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                fullWidth
                size="small"
                disableRipple
                disableElevation
                aria-label="loading"
              >
                <CircularProgress color="inherit" size={20} />
              </Button>
            )}
            {cartProgress === 'success' && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                fullWidth
                size="small"
                disableRipple
                disableElevation
                onClick={() => setCartProgress('')}
                aria-label="success"
              >
                <CheckIcon />
              </Button>
            )}
            {cartProgress === 'error' && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                fullWidth
                size="small"
                disableRipple
                disableElevation
                onClick={() => setCartProgress('')}
                aria-label="error"
              >
                <ErrorIcon />
              </Button>
            )}
            {cartProgress === '' && (
              <Tooltip title={addToCartTooltip} enterTouchDelay={500} leaveTouchDelay={1000}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.addButton}
                    fullWidth
                    disableElevation
                    disabled={disableAddToCart || props.expired}
                    onClick={handleAdd}
                    aria-label="add to order"
                  >
                    <AddToCartIcon />
                  </Button>
                </div>
              </Tooltip>
            )}
          </Box>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme:Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0.5),
		backgroundColor: theme.palette.grey[200],
		marginTop: 2,
		height: 40,
		'& > .MuiIconButton-root': {
			float: 'left',
		},
		'&:first-child': {
			borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
		},
		'&:last-child': {
			borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
		},
		'&:first-child:last-child': {
			borderRadius: theme.shape.borderRadius,
		},
	},
	input: {
		width: 50,
		padding: 0,
		marginRight: theme.spacing(0.5),
		'& > .MuiInputBase-input': {
			padding: 0,
			width: 50,
			height: 30,
			fontSize: '0.9rem',
			textAlign: 'center',
			backgroundColor: theme.palette.background.paper,
		},
		'& > .MuiFormHelperText-root': {
			marginTop: 0,
		},
	},
  inputDisabled: {
		opacity: 0.5
	},
	inStock: {
		fontSize: '20px',
		color: green[700],
		marginRight: theme.spacing(0.5),
	},
	noStock: {
		fontSize: '20px',
		color: red[800],
		marginRight: theme.spacing(0.5),
	},
	addButton: {
		padding: theme.spacing(0.25, 1),
		minWidth: 0,
		height: 30,
		'&.MuiButton-outlined': {
			backgroundColor: theme.palette.background.paper,
		},
		'& .MuiButton-startIcon': {
			marginLeft: theme.spacing(-0.25),
			marginRight: theme.spacing(0.5),
		},
	},
}));

export default UnitOfMeasureItem;
