import { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';

// Fragments
import { cartLinesFragment } from 'operations/fragments/cartLines';

// Utils
import { sendGoogleAnalyticsEvent } from 'utils/GoogleAnalytics';
import { SiteContext } from 'utils/SiteProvider';
import { useGlobalContext } from 'utils/globalContext';

// TODO: Convert selections to qgl fragment as we are using it also in queries/getCart
export const ADD_TO_CART = gql`
	mutation addToCart($input: [AddToCartInput!]!) {
		addToCart(input: $input) {
			code
			success
			message
			cart {
				id
				itemCount
				lines {
					...CartLinesFragment
				}
				subtotal
				discountTotal
				freightTotal
				taxTotal
				total
			}
		}
	}
	${cartLinesFragment}
`;

export const useAddToCart = () => {
	const { dispatch } = useGlobalContext();
	const site:any = useContext(SiteContext);

	const [mutate, { data, loading, error }] = useMutation(ADD_TO_CART);

	const customMutate = (productId:string, unitOfMeasureId:string, quantity:number) => {
		return new Promise((resolve, reject) => {
			const variables = {
				input: {
					productId,
					unitOfMeasureId,
					quantity
				}
			};

			mutate({ variables }).then(response => {

				if(response?.data?.addToCart?.success) {
					const line = response?.data?.addToCart?.cart?.lines?.filter((obj:any) => obj.productId === productId).find((obj:any) => obj.unitOfMeasure.id == unitOfMeasureId);
					if(line) {
						sendGoogleAnalyticsEvent('add_to_cart', { 
							currency: site?.settings?.currency,
							value: parseFloat(line.price).toFixed(2),
							items: [{
								'item_id': line.productId,
								'item_name': line?.product?.name,
								price: isNaN(line?.price) ? 0 : parseFloat(line?.price).toFixed(2),
								quantity,
								'item_variant': line?.unitOfMeasure?.displayName
							}]
						});
					}
					
					resolve(null);
				}
				else {
					dispatch({
						type: 'toast',
						payload: { 
							open: true,
							severity: 'error',
							message: response?.data?.addToCart?.message,
						},
					});
					reject(response?.data?.addToCart?.message);
				}
			}).catch(error => {
				dispatch({
					type: 'toast',
					payload: { 
						open: true,
						severity: 'error',
						message: error.message,
					},
				});
				reject(error);
			});
		});
	};
	return { mutate: customMutate, data, loading, error };
};

export const useAddAllToCart = () => {
	const [mutate, { data, loading, error }] = useMutation(ADD_TO_CART);

	const customMutate = (input: {productId:string, unitOfMeasureId:string, quantity:number}[]) => {
		return new Promise((resolve, reject) => {
			const variables = {
				input: input
			};

			mutate({ variables }).then(response => {
				resolve(null);
			}).catch(reject);
		});
	};
	return { mutate: customMutate, data, loading, error };
};
